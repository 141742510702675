import Vue from "vue";
import { GridPlugin, Page, Filter, Sort, Toolbar, Search, Edit, CommandColumn } from "@syncfusion/ej2-vue-grids";
import { CustomUrlAdaptor } from "@/syncfusion/CustomUrlAdaptor";
import { DataManager } from "@syncfusion/ej2-data";
import SpinnerMixin from "@/mixins/spinner";
import TitleMixin from "@/mixins/title";
import GridMixin from "@/mixins/syncfusion/grid";
Vue.use(GridPlugin);
export default Vue.extend({
    mixins: [TitleMixin, SpinnerMixin, GridMixin],
    data() {
        return {
            title: this.$t("customersTimeSlots.timeSlots"),
            data: new DataManager({
                url: process.env.VUE_APP_API_BASE_URL + "customers/time-slots/grid-data",
                removeUrl: process.env.VUE_APP_API_BASE_URL + "customers/time-slots/grid-delete",
                adaptor: new CustomUrlAdaptor()
            }),
            sortOptions: {
                columns: [
                    { field: "Order", direction: "Ascending" },
                    { field: "Name", direction: "Ascending" }
                ]
            },
            filterOptions: { type: "Menu" },
            pageSettings: { pageSize: 10 },
            toolbarOptions: [
                {
                    text: this.$t("shared.add"),
                    tooltipText: this.$t("shared.add"),
                    prefixIcon: "e-add",
                    id: "Custom_Add"
                },
                "Search"
            ],
            searchOptions: {
                fields: ["Name"],
                operator: "contains"
            },
            editSettings: {
                allowEditing: false,
                allowAdding: false,
                allowDeleting: true,
                showDeleteConfirmDialog: true,
                mode: "Dialog"
            },
            commands: [
                {
                    type: "Custom_Edit",
                    title: this.$t("shared.edit"),
                    buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" }
                },
                {
                    type: "Delete",
                    buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" }
                }
            ]
        };
    },
    provide: {
        grid: [CommandColumn, Page, Filter, Sort, Toolbar, Search, Edit]
    },
    methods: {
        toolbarClick(args) {
            if (args.item.id == "Custom_Add") {
                this.$router.push("/customers-time-slots/create");
                return;
            }
        },
        commandClick(args) {
            if (args.commandColumn && args.commandColumn.type === "Custom_Edit") {
                this.$router.push("/customers-time-slots/" + args.rowData.Id + "/edit");
                return;
            }
        },
        actionComplete(args) {
            if (args.requestType === "delete") {
                this.$toast.showSuccessToast(this.$t("customersTimeSlots.delete.toastSuccessTitle"), this.$t("customersTimeSlots.delete.toastSuccessContent", {
                    name: `${args.data[0].Name}`
                }));
            }
        }
    }
});
